export default {
    kpiGlobalReport(state) {
        return state.kpiGlobalReport;
    },
    kpiDetailedReport(state) {
        return state.kpiDetailedReport;
    },
    kpiDateGenerated(state) {
        return state.kpiDateGenerated;
    },
    shouldUpdate(state) {
        const currentTS = state.currentTS;
        const lastLoadingTS = state.lastLoadingTS;
        if (!lastLoadingTS) {
            return true;
        }
        return (currentTS - lastLoadingTS) / 1000 > 3600;
    },
};