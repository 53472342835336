import axios from "axios";

export default {
    async loadKpiReport(context, payload) {
        context.commit("setCurrentTS");
        if (!payload.refresh && !context.getters.shouldUpdate) {
            return;
        }

        try {
            const config = {
                headers: {
                    authorizationToken: "Bearer " + payload.accessToken,
                },
            };
            const URL = process.env.VUE_APP_BACKEND_BASEURL + "/kpis/report";
            const response = await axios.get(URL, config);
            if (response.status == 200) {
                console.log("esponse.data", response.data);
                context.commit("setKpiGlobalReport", response.data.globalReport.data);
                context.commit("setKpiDetailedReport", response.data.detailedReport.data);
                context.commit("setKpiDateGenerated", response.data.dateGenerated);
                context.commit("setLastLoadingTS");
            } else {
                context.commit("setKpiGlobalReport", {
                    Account: {
                        SsoRoles: [],
                    },
                });
            }
        } catch (e) {
            console.log(e);
            // silently die

            context.commit("setKpiGlobalReport", {
                Account: {
                    SsoRoles: [],
                },
            });
        }
    },
};