export default {
    setKpiGlobalReport(state, payload) {
        state.kpiGlobalReport = payload;
    },
    setKpiDetailedReport(state, payload) {
        state.kpiDetailedReport = payload;
    },
    setKpiDateGenerated(state, payload) {
        state.kpiDateGenerated = payload;
    },
    setLastLoadingTS(state) {
        state.lastLoadingTS = new Date().getTime();
    },
    setCurrentTS(state) {
        state.currentTS = new Date().getTime();
    },
};