import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import portalParametersModule from "./store/modules/portalParameters/index.js";
import chargeBackModule from "./store/modules/chargeback/index.js";
import kpiReportModule from "./store/modules/securityKPI/index.js";
import createAccountModule from "./store/modules/createAccount/index.js";
import version from "./version.js";

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  busymodalShow: false,
  errormodalShow: false,
  error: {
    title: "",
    message: "",
  },
  userinfo: undefined,
  userinfoReady: false,
  fullAccountList: [],
  version: version,
};
const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  showErrorModal(state, [title, message]) {
    state.error.title = title;
    state.error.message = message;
    state.errormodalShow = true;
  },
  setUserInfo(state, userinfo) {
    state.userinfo = userinfo;
  },
  setUserInfoState(state, ready) {
    state.userinfoReady = ready;
  },
};

export default new Vuex.Store({
  modules: {
    portalParametersModule,
    chargeBackModule,
    kpiReportModule,
    createAccountModule,
  },
  state,
  mutations,
  getters: {
    userinfo: (state) => state.userinfo,
    fullAccountList: (state) => state.fullAccountList,
    version: (state) => {
      return state.version.major + "." + state.version.minor + "." + state.version.build;
    },
    sidebarShow: (state) => state.sidebarShow,
    sidebarMinimize: (state) => state.sidebarMinimize,
  },
});
