import axios from "axios";
import store from "./store";
//import mockPerm from "./mockPermission"

async function fetchUserinfo(user, accessToken) {
  //return mockPerm
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
      },
    };
    const URL = process.env.VUE_APP_BACKEND_BASEURL + "/users/" + user;
    const response = await axios.get(URL, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while fetching your data");
  }
}

async function fetchAccountList(accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
      },
    };
    const URL = process.env.VUE_APP_BACKEND_BASEURL + "/accounts";
    const response = await axios.get(URL, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while fetching your data");
  }
}

async function userinfo2(inst) {
  if (inst.$store.userinfo == undefined) {
    try {
      let userinfo = await inst.$auth.getUser();
      let accessToken = await inst.$auth.getAccessToken();
      let userinfo2 = await fetchUserinfo(userinfo.preferred_username, accessToken);
      // inst.$store.commit('set', ['userinfo', userinfo2])
      inst.$store.commit("setUserInfo", userinfo2);
      // to be aligned with the autorizer  --> only AccountAdmin
      if (
        // userinfo2.User.AccountOwner.includes('ALL') ||
        userinfo2.User.AccountAdmin.includes("ALL")
        // userinfo2.User.SecurityAudit.includes('ALL')
      ) {
        let accounts = await fetchAccountList(accessToken);
        let accounts2 = accounts.map((item) => {
          if (item.hasOwnProperty("AccountName")) return { ...item, AccountAlias: item.AccountName };
          else return { ...item };
        });
        inst.$store.commit("set", ["fullAccountList", accounts2]);
      }
      inst.$store.commit("setUserInfoState", true);
      return userinfo2;
    } catch (err) {
      console.log(err);
    }
  } else {
    return inst.$store.userinfo;
  }
}

function isSuperAdmin() {
  if (store.getters.userinfo.User.IsSuperAdmin) {
    return true;
  }
  return false;
}

function isAccountOwner(accountId) {
  if (isSuperAdmin()) return true;

  // backed debug
  // return true
  // end backend debug

  if (
    store.getters.userinfo.User.AccountOwner.includes("ALL") ||
    store.getters.userinfo.User.AccountOwner.findIndex((item) => {
      return item.AccountId === accountId;
    }) >= 0
  ) {
    return true;
  }
  return false;
}

function isAccountAdmin(accountId) {
  if (isSuperAdmin() || isAccountOwner(accountId)) return true;

  if (
    store.getters.userinfo.User.AccountAdmin.includes("ALL") ||
    store.getters.userinfo.User.AccountAdmin.findIndex((item) => item.AccountId === accountId) >= 0
  ) {
    return true;
  }
  return false;
}

function isSecurityAudit(accountId) {
  if (isSuperAdmin() || isAccountOwner(accountId) || isAccountAdmin(accountId)) return true;

  if (
    store.getters.userinfo.User.SecurityAudit.includes("ALL") ||
    store.getters.userinfo.User.SecurityAudit.findIndex((item) => item.AccountId === accountId) >= 0
  ) {
    return true;
  }
  return false;
}

function resolveAlias(accountId, listAccount) {
  const found = listAccount.find((element) => {
    return accountId.localeCompare(element.AccountId) == 0;
  });
  if (found != undefined) {
    return found.AccountName == undefined ? found.AccountAlias : found.AccountName;
  } else {
    return undefined;
  }
}

export default {
  // {"User":{"UserId":"LZ5368-A@equans.com","IsSuperAdmin":true,"AccountOwner":["ALL"],"AccountAdmin":["ALL"],"SecurityAudit":["ALL"]}}
  install(Vue, options) {
    if (!options || !options.store) {
      throw new Error("Please initialise plugin with a Vuex store.");
    }

    Vue.prototype.$permissionInit = async function() {
      try {
        await userinfo2(this);
      } catch (err) {
        console.log(err);
      }
    };

    Vue.prototype.$resolveAlias = function(accountId) {
      if (store.getters.userinfo == undefined) return undefined;

      var found = resolveAlias(accountId, Vue.prototype.$getAccountList("owner"));
      if (found == undefined) found = resolveAlias(accountId, Vue.prototype.$getAccountList("admin"));
      if (found == undefined) found = resolveAlias(accountId, Vue.prototype.$getAccountList("securityaudit"));

      return found;
    };

    Vue.prototype.$isSuperAdmin = function() {
      if (store.getters.userinfo == undefined) return false;

      return isSuperAdmin();
    };
    Vue.prototype.$isAccountOwner = function(accountId) {
      if (store.getters.userinfo == undefined) return false;

      return isAccountOwner(accountId);
    };
    Vue.prototype.$isAccountAdmin = function(accountId) {
      if (store.getters.userinfo == undefined) return false;

      return isAccountAdmin(accountId);
    };
    Vue.prototype.$isSecurityAudit = function(accountId) {
      if (store.getters.userinfo == undefined) return false;

      return isSecurityAudit(accountId);
    };
    Vue.prototype.$getAccountList = function(level) {
      // level : securityaudit, owner, admin
      switch (level) {
        case "owner":
          if (store.getters.userinfo.User.AccountOwner.includes("ALL")) return store.getters.fullAccountList;
          else return store.getters.userinfo.User.AccountOwner;
          break;
        case "admin":
          if (store.getters.userinfo.User.AccountAdmin.includes("ALL")) return store.getters.fullAccountList;
          else return store.getters.userinfo.User.AccountAdmin;
          break;
        case "securityaudit":
          if (store.getters.userinfo.User.SecurityAudit.includes("ALL")) return store.getters.fullAccountList;
          else return store.getters.userinfo.User.SecurityAudit;
          break;
      }
    };
  },
};
